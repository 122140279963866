import React from 'react'

import Button from '@eig-builder/core-ui/Button'
import { goTo } from './../../helpers/go-to-helper'

import './style/sub-hero.scss'
import { string, bool } from 'prop-types'

const SubHero = ({ pageClass, title, subTitle, buttonTitle, buttonLink, backgroundImage, currentPage, dataElementLocation, buttonVisibility, hasOverlay = true }) => (
  <section className={`${ pageClass } sub-hero ${ hasOverlay ? 'overlay' : '' }`}>
    <div className='background-image' style={{ backgroundImage: 'url(' + backgroundImage + ')' }} />
    <div className='container text-center'>
      <div className='row'>
        <div className='col-md-10 mx-auto'>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          <p className='body-large'>{subTitle}</p>
          <Button
            onClick={() => goTo(buttonLink)}
            dataElementLocation={dataElementLocation}
            dataElementLabel={'signup'}
            dataElementId={currentPage + '-sub-hero-item-signup'}
            color='default'
            variant='contained'
            className={'button-xl button-white signup-button ' + buttonVisibility}
            size='large'>
            {buttonTitle}
          </Button>
        </div>
      </div>
    </div>
  </section>
)

SubHero.propTypes = {
  pageClass: string,
  title: string,
  subTitle: string,
  buttonTitle: string,
  buttonLink: string,
  backgroundImage: string,
  currentPage: string,
  dataElementLocation: string,
  buttonVisibility: string,
  hasOverlay: bool }

export default SubHero
