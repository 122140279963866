import React from 'react'

import NoSsr from '@material-ui/core/NoSsr'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import ParagraphWithImage from '@eig-builder/compositions-paragraph-with-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import InPageImage from '../components/in-page-image'
import SubHero from '../components/sub-hero'
import CtaBlue from '../components/cta-blue'
import DiscountTerms from '../components/discount-terms'

import HeroBackgroundImage from './../images/email-hero.png'

import DiscountBanner from '../components/discount-banner'

class IndexPage extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title='Free Email Marketing, CRM And Websites For Small Business'
          description='Free email marketing, integrated with your website and CRM that is designed to engage your audience, manage your prospects and grow your business.'
          keywords={[
            `web hosting`,
            `domain names`,
            `web site`,
            `search engine optimization`,
            `hosting`,
            `servers`,
            `email marketing`,
          ]}
          pageType={'product_page'}
          flowType={'none'}
        />
        <DiscountBanner />
        <SubHero
          pageClass={'marketing-hero'}
          title={"Creative Email.<br />Email Marketing That's Crazy Easy"}
          subTitle={'AI builds your email. You build your business.'}
          buttonTitle={'Get started free'}
          backgroundImage={HeroBackgroundImage}
          buttonLink={process.env.GATSBY_SIGNUP_SITE_URL}
          currentPage={'email-marketing'}
          dataElementLocation={DataElementLocations.HERO}
          buttonVisibility={'show-button'}
        />
        <section>
          <div className='container section-padding text-section large-gutter'>
            <NoSsr>
              <ParagraphWithImage
                alignment='left'
                graphic={
                  <InPageImage
                    fileName='email-1.png'
                    classes='crop left'
                    alt='Laptop with creative email'
                  />
                }
                title='AI Makes Awesome Emails'
                description={
                  <React.Fragment>
                    <p className='black-60'>
                      Creative Email uses artificial intelligence to create the
                      kind of email you want to send. Our email editor
                      automagically creates beautiful layouts with your branding
                      by pulling in your colors, images, and design elements
                      right from your website. Just add some text or landing
                      page links and you’re done.
                    </p>
                    <p className='black-60'>
                      Want to add more sections, images or calls to action? Not
                      a problem. Our prebuilt layouts prevent you from breaking
                      the design and our color tuner makes sure your contrast
                      reads well for all eyes and on all devices.
                    </p>
                  </React.Fragment>
                }
              />
              <ParagraphWithImage
                alignment='right'
                graphic={
                  <InPageImage
                    fileName='email-2.png'
                    classes='crop'
                    alt='Desktop with creative email'
                  />
                }
                title='Grow Your Contacts While You Sleep'
                description={
                  <p className='black-60'>
                    Creative Email is also connected to our included Website
                    &amp; Contacts CRM system. When someone fills out a dynamic
                    form on your website, the data is automatically added inside
                    the email marketing system. There’s no need to embed any
                    code or do a technical integration. It’s all-in-one and it
                    just works to help you grow your audience.
                  </p>
                }
              />
              <ParagraphWithImage
                alignment='left'
                graphic={
                  <InPageImage
                    fileName='email-3.png'
                    classes='crop left'
                    alt='Laptop with creative email stats'
                  />
                }
                title='Reporting That Makes Sense'
                description={
                  <React.Fragment>
                    <p className='black-60'>
                      Know who engages with your emails and see where they are
                      on a map. Follow their engagement across your website,
                      store or blog. It's all available in beautiful graphical
                      reports. No rocket science degree or technical
                      understanding necessary.
                    </p>
                    <p className='black-60'>
                      Clicks, open rates, bounces, map locations and device data
                      are all included. Our CRM even color codes contacts who
                      open, bounce or ignore emails so you know who is a better
                      potential customer.
                    </p>
                  </React.Fragment>
                }
              />
              <ParagraphWithImage
                alignment='right'
                graphic={
                  <InPageImage
                    fileName='email-4.png'
                    classes='crop'
                    alt='Person working on creative email'
                  />
                }
                title='Ideal For Your Audience'
                description={
                  <React.Fragment>
                    <p className='black-60'>
                      If you’re starting a small business or hobby and just want
                      to send great looking emails to customers and prospects,
                      you need to check out Creative Email. You’ll get an
                      all-in-one solution that works with your website, domain
                      and ecommerce store. Creative Email is incredibly easy. No
                      geeky coding or time wasting required.
                    </p>
                  </React.Fragment>
                }
              />
            </NoSsr>
          </div>
        </section>
        <section>
          <div className='container section-padding product-cards py-0'>
            <div className='row'>
              <div className='col-lg-10 body-large mx-auto'>
                <CtaBlue
                  eventName='start_onboarding_email_marketing'
                  title={'Try it first.  No commitment necessary.'}
                  buttonTitle={'Try it for free'}
                  buttonLink={process.env.GATSBY_SIGNUP_SITE_URL}
                  currentPage={'features'}
                  dataElementLocation={DataElementLocations.BODY}
                />
              </div>
            </div>
          </div>
          <DiscountTerms />
        </section>
      </Layout>
    )
  }
}

export default IndexPage
